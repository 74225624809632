import { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import * as EmailValidator from 'email-validator';
import { NAME } from '@app/account/constants';

// UI
import Link from 'next/link';
import Headline from '@ui/components/Headline';
import Subtitle from '@ui/components/Subtitle';
import TextInput from '@ui/components/TextInput';
import Checkbox from '@ui/components/Checkbox';
import ButtonLoadingSpinner from '@ui/components/ButtonLoadingSpinner';
import RevealPasswordEye from '@ui/components/RevealPasswordEye';

// Redux
import { getError, getLoading, login, reset } from '@app/account/models/login';

// Types
import { LoginValidationErrors, LoginFormValues } from '../../types';
import type { FC } from 'react';
import { validateCoupon } from '@app/account/models/coupon';

export interface Props {
    isBundle?: boolean; // is bundle subscription
    hideIntro?: boolean; // hide headlines
    fixedPlanId?: string;
    successRedirectUrl?: string; // redirect after successful login
    resetPasswordRedirectUrl?: string; // login url in pw-reset form
    signupUrl?: string; // url to signup page
    welcomeUrl?: string; // redirect after successful login and having a valid subscription
    loginButtonLabelI18nKey?: string; // i18n key for login button label
}

const LoginForm: FC<Props> = ({
    isBundle,
    hideIntro,
    fixedPlanId,
    successRedirectUrl,
    resetPasswordRedirectUrl,
    signupUrl,
    welcomeUrl,
    loginButtonLabelI18nKey = 'login-btn',
}) => {
    const { t } = useTranslation(NAME);

    const dispatch = useDispatch();
    const router = useRouter();
    const loading = useSelector(getLoading);
    const error = useSelector(getError);

    const [revealPassword, setRevealPassword] = useState(false);

    const { query } = router;
    const { coupon } = query;

    // Reset on unmount
    useEffect(
        () => () => {
            dispatch(reset());
        },
        [dispatch],
    );

    useEffect(() => {
        if (coupon) {
            dispatch(validateCoupon(coupon as string));
        }
    }, [dispatch, coupon]);

    const validate = (values: LoginFormValues): LoginValidationErrors => {
        const errors: LoginValidationErrors = {};

        if (!values.email) {
            errors.email = 'required';
        } else if (!EmailValidator.validate(values.email?.trim())) {
            errors.email = 'invalid-email';
        }
        if (!values.password) {
            errors.password = 'required';
        }
        if (values.password && values.password?.length > 128) {
            errors.password = 'too-long';
        }

        return errors;
    };

    const togglePassword = () => {
        setRevealPassword(!revealPassword);
    };

    const onSubmit = async (values: LoginFormValues) => {
        dispatch(
            login({
                ...values,
                query: {
                    ...query,
                    planId: fixedPlanId || (query.planId as string),
                },
                email: values.email?.trim(),
                successRedirectUrl,
                welcomeUrl,
                isBundle,
            }),
        );
    };

    return (
        <div className={`mx-auto ${hideIntro ? 'mt-1' : 'mt-6'} w-full sm:w-96`}>
            {!hideIntro && (
                <div className="mb-8">
                    <Headline>{t('login-title')}</Headline>
                    <Subtitle>{t('login-description')}</Subtitle>
                </div>
            )}

            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} data-cy="login-form">
                        <div className="divide-y rounded-xl border bg-white shadow">
                            <Field
                                name="email"
                                component={TextInput}
                                icon="✉️"
                                placeholder={t('placeholder-email')}
                            />

                            <Field
                                name="password"
                                component={TextInput}
                                type={revealPassword ? 'text' : 'password'}
                                icon="🔐"
                                placeholder={t('placeholder-password')}
                            >
                                <RevealPasswordEye
                                    togglePassword={togglePassword}
                                    revealPassword={revealPassword}
                                />
                            </Field>
                        </div>

                        {error && (
                            <div
                                className="mt-4 flex items-center rounded-xl bg-red-50 p-5 text-sm text-red-500"
                                data-cy="response-error"
                            >
                                <span className="ml-2">
                                    {error?.response?.data?.statusCode
                                        ? t(`login-${error.response.data.statusCode}`)
                                        : error?.response?.data?.message}
                                </span>
                            </div>
                        )}

                        <button
                            type="submit"
                            disabled={loading}
                            className="mt-4 w-full rounded-xl bg-blue-500 p-5 text-white transition-colors hover:bg-blue-600"
                        >
                            {loading ? <ButtonLoadingSpinner /> : t(loginButtonLabelI18nKey)}
                        </button>

                        <div className="mt-8 flex w-full flex-row justify-between">
                            <Field
                                id="rememberMe"
                                name="rememberMe"
                                type="checkbox"
                                render={Checkbox}
                                className="p-0"
                                defaultValue={true}
                            >
                                {t('stay-logged-in')}
                            </Field>

                            <Link
                                href={{
                                    pathname: '/reset-password',
                                    query: {
                                        ...query,
                                        ...(resetPasswordRedirectUrl
                                            ? { redirectUrl: resetPasswordRedirectUrl }
                                            : {}),
                                    },
                                }}
                                passHref
                            >
                                <p
                                    className="cursor-pointer text-sm text-gray-500 transition-colors hover:text-blue-500"
                                    data-cy="forgot-password-link"
                                >
                                    {t('forgot-password')}
                                </p>
                            </Link>
                        </div>
                    </form>
                )}
            />

            <div className="mt-12 text-center text-sm text-gray-500">
                {t('dont-have-account')}
                <Link
                    href={{
                        pathname: signupUrl || '/signup',
                        query: query,
                    }}
                    passHref
                    data-cy="register-link"
                >
                    <p className="inline cursor-pointer transition-colors hover:text-blue-500">
                        &nbsp;<span className="underline">{t('register')}</span>
                    </p>
                </Link>
            </div>
        </div>
    );
};

export default LoginForm;
